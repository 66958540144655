import {
  GooglePlayBadge,
  NotStyledParser,
  ResponsiveTypography,
  SectionOuter,
} from '@/shared/ui';
import { Box, Typography } from '@mui/material';
import Image from 'next/image';

import { TBlockAmmanaTargetOffer } from '@/shared/api/homepage-api';
import { useCommonApi } from '@/shared/contexts';

type AmmanaTargetOfferProps = Pick<
  TBlockAmmanaTargetOffer,
  'title' | 'text' | 'qrCode' | 'imageDesktop' | 'imageMobile'
>;

export const AmmanaTargetOffer = (props: AmmanaTargetOfferProps) => {
  const { title, imageMobile, imageDesktop, text, qrCode } = props;

  const commonApiContext = useCommonApi();

  const googlePlayLink =
    commonApiContext?.layoutSettings?.attributes.globalLinks?.googlePlayLink;

  return (
    <SectionOuter
      color="secondary.95"
      disablePaddings
      sx={{
        position: 'relative',
        backgroundImage: {
          md: `url(${imageDesktop?.data?.attributes.url})`,
        },
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right 30%',
        p: {
          xs: 0,
          md: 8,
        },
        mb: {
          xs: 2.5,
          md: 7.25,
        },
        mt: {
          xs: 2.5,
          md: 0,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: {
            md: 500,
          },
          textAlign: {
            xs: 'center',
            md: 'left',
          },
          flexDirection: 'column',
          alignItems: 'center',
        }}
        display={{
          xs: 'flex',
          md: 'block',
        }}
        p={{
          xs: 2.25,
          md: 0,
        }}
      >
        <ResponsiveTypography
          variantMap={{
            xs: 'headline4',
            md: 'headline1',
          }}
          color="neutral.20"
          sx={{
            mb: { xs: 1.25, md: 3.25 },
          }}
        >
          {title}
        </ResponsiveTypography>
        <Box
          mb={8.25}
          display={{
            xs: 'none',
            md: 'block',
          }}
        >
          <Typography component="div" variant="text4" color="neutral.50">
            <NotStyledParser data={text} />
          </Typography>
        </Box>
        <Box
          mb={2.25}
          display={{
            xs: 'block',
            md: 'none',
          }}
        >
          <Typography component="div" variant="text6" color="neutral.20">
            <NotStyledParser data={text} />
          </Typography>
        </Box>
        {googlePlayLink ? <GooglePlayBadge href={googlePlayLink} /> : null}
      </Box>

      <Box
        display={{
          xs: 'flex',
          md: 'none',
        }}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Image
          src={imageMobile?.data?.attributes.url}
          alt={imageMobile?.data?.attributes.alternativeText || ''}
          height={imageMobile?.data?.attributes.height}
          width={imageMobile?.data?.attributes.width}
          style={{
            objectFit: 'contain',
            height: 'auto',
            maxWidth: '100%',
            width: 400,
            display: 'block',
          }}
        />
      </Box>

      {qrCode?.data ? (
        <Box
          display={{
            xs: 'none',
            md: 'block',
          }}
          sx={{
            width: 137,
            height: 137,
            position: 'absolute',
            top: 52,
            right: 52,
          }}
        >
          <Image
            src={qrCode?.data?.attributes.url}
            alt={qrCode?.data?.attributes.alternativeText || ''}
            height={qrCode?.data?.attributes.height}
            width={qrCode?.data?.attributes.width}
            style={{
              objectFit: 'contain',
              height: 'auto',
            }}
          />
        </Box>
      ) : null}
    </SectionOuter>
  );
};
