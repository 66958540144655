import React from 'react';
import { Box, Button, Card, Grid, Link, Stack } from '@mui/material';
import Image from 'next/image';

import { ResponsiveTypography, SectionContainer } from '@/shared/ui';
import { MobileAppSectionV2 } from '@/shared/api/homepage-api';
import { useCommonApi } from '@/shared/contexts';

type DownloadAppBannerProps = Pick<
  MobileAppSectionV2,
  | 'title'
  | 'qrCode'
  | 'image'
  | 'googlePlayBtn'
  | 'listItems'
  | 'listStyleImage'
>;

export const DownloadAppBanner = (props: DownloadAppBannerProps) => {
  const { qrCode, title, listItems, listStyleImage, image, googlePlayBtn } =
    props;

  const commonApiContext = useCommonApi();

  const googlePlayLink =
    commonApiContext?.layoutSettings?.attributes.globalLinks?.googlePlayLink;

  const googlePlayButton = googlePlayBtn ? (
    <Button
      component={Link}
      href={googlePlayLink}
      target="_blank"
      sx={{
        py: 2,
        px: 5,
        width: { xs: '100%', md: 'auto' },
      }}
    >
      {googlePlayBtn}
    </Button>
  ) : null;

  return (
    <SectionContainer>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} md={6}>
          <ResponsiveTypography
            variantMap={{
              xs: 'headline5',
              md: 'headline3',
            }}
            color="common.onPrimaryContainer"
            sx={{
              mb: {
                xs: 3.75,
                md: 0,
              },
            }}
          >
            {title}
          </ResponsiveTypography>
          <Box
            component="ul"
            p={0}
            my={{
              xs: 2.5,
              md: 5,
            }}
          >
            {listItems.map((item) => (
              <Stack
                component="li"
                mb={2.5}
                direction="row"
                alignItems="center"
                spacing={1.25}
                key={item.id}
              >
                <Image
                  src={listStyleImage?.data?.attributes.url}
                  alt={
                    listStyleImage?.data?.attributes.alternativeText ||
                    'list style item'
                  }
                  width={20}
                  height={20}
                />
                <ResponsiveTypography
                  variantMap={{
                    xs: 'text6',
                    md: 'text4',
                  }}
                >
                  {item.text}
                </ResponsiveTypography>
              </Stack>
            ))}
          </Box>
          <Box
            display={{
              xs: 'none',
              md: 'block',
            }}
          >
            {googlePlayButton}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            variant="animated"
            sx={{
              height: {
                xs: 213,
                sm: 324,
                lg: 365,
              },
              borderRadius: 2.5,
              position: 'relative',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Image
              src={image?.data?.attributes.url}
              alt={image?.data?.attributes.alternativeText || ''}
              height={image?.data?.attributes.height}
              width={image?.data?.attributes.width}
              style={{
                objectFit: 'contain',
                height: 'auto',
                maxHeight: '90%',
                display: 'block',
              }}
            />
            {qrCode?.data ? (
              <Box
                display={{
                  xs: 'none',
                  sm: 'block',
                }}
                sx={{
                  width: 137,
                  height: 137,
                  position: 'absolute',
                  top: 20,
                  right: 20,
                }}
              >
                <Image
                  src={qrCode?.data?.attributes.url}
                  alt={qrCode?.data?.attributes.alternativeText || ''}
                  height={qrCode?.data?.attributes.height}
                  width={qrCode?.data?.attributes.width}
                  style={{
                    objectFit: 'contain',
                    height: 'auto',
                  }}
                />
              </Box>
            ) : null}
          </Card>
          <Box
            mt={2.5}
            display={{
              md: 'none',
            }}
          >
            {googlePlayButton}
          </Box>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};
