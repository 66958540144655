import { Box, Grid, List, ListItem } from '@mui/material';
import { ResponsiveTypography, SectionContainer } from '@/shared/ui';
import Image from 'next/image';

import { IImage } from '@/shared/types';

type BaseColumn = {
  title: string;
  imageDesktop: IImage;
  imageMobile: IImage;
};

type TwoColumnsBlockProps = {
  leftColumn: { subTitle?: string } & BaseColumn;
  rightColumn: {
    list: { id: string; text: string; icon: IImage }[];
  } & BaseColumn;
};

export const TwoColumnsBlock = ({
  leftColumn,
  rightColumn,
}: TwoColumnsBlockProps) => {
  return (
    <SectionContainer sx={{ my: 2.5 }}>
      <Grid
        container
        rowSpacing={{ xs: 2, md: 0 }}
        columnSpacing={{ xs: 0, md: 2 }}
      >
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={1}
            height="100%"
            borderRadius={2}
            sx={{
              backgroundColor: 'secondary.50',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="80%"
              sx={(theme) => ({
                gap: 2.5,
                p: {
                  xs: theme.spacing(2.5, 0, 2.5, 2.5),
                  md: theme.spacing(3.75, 0, 3.75, 3.75),
                },
              })}
            >
              <ResponsiveTypography
                color="neutral.20"
                variantMap={{ xs: 'headline8', md: 'headline5' }}
                sx={{ maxWidth: { xs: '200px', md: '250px' } }}
              >
                {leftColumn.title}
              </ResponsiveTypography>

              <ResponsiveTypography
                color="neutral.50"
                variantMap={{ xs: 'text6', md: 'text4' }}
              >
                {leftColumn.subTitle}
              </ResponsiveTypography>
            </Box>

            {/** Image on Desktop devices */}
            <Box
              display={{
                xs: 'none',
                md: 'flex',
              }}
              alignItems="center"
              justifyContent="end"
              flexShrink={0}
              p={1.75}
            >
              <Image
                src={leftColumn?.imageDesktop?.data?.attributes?.url}
                alt={
                  leftColumn?.imageDesktop?.data?.attributes?.alternativeText ||
                  ''
                }
                width={105}
                height={136}
              />
            </Box>

            {/** Image on Mobile devices */}
            <Box
              display={{
                xs: 'flex',
                md: 'none',
              }}
              alignItems="center"
              justifyContent="end"
              flexShrink={0}
              p={(theme) => theme.spacing(3.25, 2.5, 3.25, 0)}
            >
              <Image
                src={leftColumn?.imageMobile?.data?.attributes?.url}
                alt={
                  leftColumn?.imageMobile?.data?.attributes?.alternativeText ||
                  ''
                }
                width={105}
                height={136}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={1}
            height="100%"
            borderRadius={2}
            sx={{ backgroundColor: 'primary.95' }}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="60%"
              gap={{ xs: 2.5, md: 1.5 }}
              sx={(theme) => ({
                p: {
                  xs: theme.spacing(2.5, 0, 2.5, 2.5),
                  md: theme.spacing(3.75, 0, 3.75, 3.75),
                },
              })}
            >
              <ResponsiveTypography
                color="neutral.50"
                variantMap={{ xs: 'text6', md: 'text4' }}
              >
                {rightColumn.title}
              </ResponsiveTypography>

              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.75,
                  p: 0,
                }}
              >
                {rightColumn.list.map((listItem) => (
                  <ListItem key={listItem.id} sx={{ p: 0, gap: 1 }}>
                    <Image
                      src={listItem?.icon?.data?.attributes.url}
                      alt={
                        listItem?.icon?.data?.attributes.alternativeText ||
                        'listItem'
                      }
                      width={20}
                      height={20}
                    />

                    <ResponsiveTypography
                      color="neutral.20"
                      variantMap={{ xs: 'headline8', md: 'headline5' }}
                    >
                      {listItem.text}
                    </ResponsiveTypography>
                  </ListItem>
                ))}
              </List>
            </Box>

            {/** Image on Desktop devices */}
            <Box
              display={{ xs: 'none', md: 'flex' }}
              alignItems="end"
              justifyContent="end"
            >
              <Image
                src={rightColumn?.imageDesktop?.data?.attributes?.url}
                alt={
                  rightColumn?.imageDesktop?.data?.attributes
                    ?.alternativeText || ''
                }
                width={270}
                height={164}
                style={{ objectFit: 'cover' }}
              />
            </Box>

            {/** Image on Mobile devices */}
            <Box
              display={{ xs: 'flex', md: 'none' }}
              alignItems="center"
              justifyContent="end"
            >
              <Image
                src={rightColumn?.imageMobile?.data?.attributes?.url}
                alt={
                  rightColumn?.imageMobile?.data?.attributes?.alternativeText ||
                  ''
                }
                width={125}
                height={188}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};
