import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { TBlockRequirementsV2 } from '@/shared/api/homepage-api';
import { ResponsiveTypography, SectionContainer } from '@/shared/ui';
import {
  RequirementsCardLarge,
  RequirementsCardSmallLeft,
  RequirementsCardSmallRight,
} from '@/components/home/block-requirements-v2/cards';
import { HorizontalSlider } from '@/shared/ui/horizontal-slider';
import { handleActionButtonClick } from '@/utils';

type BlockRequirementsV2Props = Pick<
  TBlockRequirementsV2,
  | 'title'
  | 'cardLarge'
  | 'cardLargeChips'
  | 'cardSmallRight'
  | 'cardSmallLeft'
  | 'cardLargeChipsMobile'
  | 'buttonText'
>;

export const BlockRequirementsV2 = (props: BlockRequirementsV2Props) => {
  const {
    title,
    cardLargeChips,
    cardLarge,
    cardSmallRight,
    cardSmallLeft,
    cardLargeChipsMobile,
    buttonText
  } = props;

  return (
    <SectionContainer>
      <Box
        mb={{
          xs: 2.5,
          md: 5,
        }}
      >
        <ResponsiveTypography
          textAlign={{
            md: 'center'
          }}
          component="h2"
          variantMap={{
            xs: 'headline5',
            md: 'headline3',
          }}
        >
          {title}
        </ResponsiveTypography>
      </Box>

      <Box
        display={{
          xs: 'none',
          md: 'block',
        }}
      >
        <Grid container spacing={2.5}>
          <Grid item xs={6}>
            <RequirementsCardLarge
              title={cardLarge.title}
              chips={cardLargeChips}
              icon={cardLarge.icon}
              background={cardLarge.background}
              chipsMobile={cardLargeChipsMobile}
              backgroundMobile={cardLarge.backgroundMobile}
            />
          </Grid>
          <Grid item xs={3}>
            <RequirementsCardSmallLeft
              icon={cardSmallLeft.icon}
              title={cardSmallLeft.title}
              images={cardSmallLeft.images}
            />
          </Grid>
          <Grid item xs={3}>
            <RequirementsCardSmallRight
              icon={cardSmallRight.icon}
              title={cardSmallRight.title}
              images={cardSmallRight.images}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display={{
          xs: 'block',
          md: 'none',
        }}
      >
        <HorizontalSlider
          items={[
            <RequirementsCardLarge
              key={1}
              title={cardLarge.title}
              chips={cardLargeChips}
              icon={cardLarge.icon}
              background={cardLarge.background}
              backgroundMobile={cardLarge.backgroundMobile}
              chipsMobile={cardLargeChipsMobile}
            />,
            <RequirementsCardSmallLeft
              key={2}
              icon={cardSmallLeft.icon}
              title={cardSmallLeft.title}
              images={cardSmallLeft.images}
            />,
            <RequirementsCardSmallRight
              key={3}
              icon={cardSmallRight.icon}
              title={cardSmallRight.title}
              images={cardSmallRight.images}
            />,
          ]}
          spacing={1.25}
        />
      </Box>
      {buttonText ?
        <Box mt={{xs: 3.75, md: 5}} display="flex" justifyContent="center">
          <Button onClick={handleActionButtonClick} fullWidth sx={{maxWidth: 363}}>{buttonText}</Button>
        </Box>
        : null
      }
    </SectionContainer>
  );
};
