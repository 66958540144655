import React from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import Image from 'next/image';

import { NotStyledParser } from '@/shared/ui';
import { IImage } from '@/shared/types';
import { TBlockRequirementsV2 } from '@/shared/api/homepage-api';

interface RequirementsCardSmallRightProps {
  icon: IImage;
  title: string;
  images: TBlockRequirementsV2['cardSmallRight']['images'];
}

export const RequirementsCardSmallRight = (
  props: RequirementsCardSmallRightProps,
) => {
  const { title, icon, images } = props;

  return (
    <Card
      variant="animated"
      sx={{
        p: 3.75,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: {
          xs: 285,
          md: 'auto',
        },
      }}
    >
      <Box>
        <Image
          src={icon?.data?.attributes.url}
          alt={icon?.data?.attributes.alternativeText || ''}
          width={35}
          height={35}
        />
        <Typography variant="headline6" component="div" sx={{ mt: 2.5 }}>
          <NotStyledParser data={title} />
        </Typography>
      </Box>

      <Stack direction="row" justifyContent="space-between">
        {images?.data?.map((image, index) => (
          <Image
            key={index}
            src={image.attributes.url}
            alt={image.attributes.alternativeText || ''}
            height={75}
            width={75}
          />
        ))}
      </Stack>
    </Card>
  );
};
