import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import Image from 'next/image';

import { TBlockNumbers } from '@/shared/api/homepage-api';
import {
  NotStyledParser,
  ResponsiveTypography,
  SectionContainer,
} from '@/shared/ui';
import { handleActionButtonClick } from '@/utils';

type BlockNumbersProps = Pick<TBlockNumbers, 'title' | 'numbers' | 'buttonText'>;

export const BlockNumbers = (props: BlockNumbersProps) => {
  const { title, numbers, buttonText } = props;

  return (
    <SectionContainer>
      <Box
        sx={(theme) => ({
          borderTop: {
            xs: `none`,
            md: `1px solid ${theme.palette.neutral[90]}}`,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            pt: {
              xs: 0,
              md: 5,
            },
            pb: {
              xs: 3.5,
              md: 5,
            },
            borderBottom: `1px solid ${theme.palette.neutral[90]}}`,
          })}
        >
          <Grid
            container
            spacing={{ xs: 0, md: 4 }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
          >
            <Grid item xs={12} md={4}>
              <Stack
                justifyContent="space-between"
                height="100%"
                ml={{
                  md: 2,
                  lg: 4,
                }}
              >
                {title ? (
                  <ResponsiveTypography
                    variantMap={{
                      xs: 'headline5',
                      md: 'headline3',
                    }}
                    component="h2"
                    sx={{
                      '& > *': {
                        whiteSpace: {
                          xs: 'nowrap',
                          md: 'wrap',
                        },
                        display: {
                          md: 'block',
                        },
                      },
                    }}
                  >
                    <NotStyledParser data={title} />
                  </ResponsiveTypography>
                ) : null}
                {buttonText ?
                  <Button onClick={handleActionButtonClick} sx={{ display: { xs: 'none', md: 'block' } }} fullWidth>
                    {buttonText}
                  </Button>
                  : null
                }
              </Stack>
            </Grid>


            <Grid container item xs={12} md={8}>
              {numbers?.map((item, index) => (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mr: 'auto',
                    display: {
                      xs: 'flex',
                      md: 'block',
                    },
                    justifyItems: 'start',
                    pt: {
                      xs: 3.5,
                      md: 0,
                    },
                    pb: {
                      md: 3,
                    },

                    '&:hover': {
                      '& .MuiTypography-root': {
                        color: 'primary.40',
                      },
                    },
                  }}
                >
                  {item.image?.data ? (
                    <Box
                      sx={{
                        flex: {
                          xs: '0 0 70px',
                          md: '0 0 118px',
                        },
                        mr: {
                          xs: 2.5,
                          md: 0,
                        },
                        ml: {
                          xs: index % 2 ? 11.25 : 0,
                          md: 0,
                        },
                        mb: {
                          md: 2.5,
                        },
                      }}
                    >
                      <Image
                        src={item.image?.data.attributes.url}
                        alt={item.image?.data.attributes.alternativeText || ''}
                        width={item.image?.data.attributes.width}
                        height={item.image?.data.attributes.height}
                        style={{
                          height: 'auto',
                        }}
                      />
                    </Box>
                  ) : null}

                  <Box
                    display={{
                      xs: 'block',
                      md: 'none',
                    }}
                  >
                    <Typography variant="headline5" color="neutral.20">
                      {item.value}
                    </Typography>
                    <Typography variant="text6" color="neutral.50">
                      {item.valueDescription}
                    </Typography>
                  </Box>
                  <Box
                    display={{
                      xs: 'none',
                      md: 'block',
                    }}
                  >
                    <Typography
                      variant="headline1"
                      color="neutral.20"
                      sx={{
                        mb: 1.25,
                        transition: 'color .2s ease',
                      }}
                    >
                      {item.value}
                    </Typography>
                    <Typography
                      variant="text4"
                      color="neutral.50"
                      sx={{
                        transition: 'color .2s ease',
                      }}
                    >
                      {item.valueDescription}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {buttonText ?
        <Button onClick={handleActionButtonClick} sx={{ display: { md: 'none' }, mt: 3.75 }} fullWidth>
          {buttonText}
        </Button>
        : null
      }
    </SectionContainer>
  );
};
