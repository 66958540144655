import React from 'react';
import { Box, Container } from '@mui/material';
import Image from 'next/image';
import { ImageTitleText as ImageTitleTextBlock } from '@/shared/api/homepage-api';
import { ResponsiveTypography } from '@/shared/ui';

type ImageTitleTextBlockProps = Pick<
  ImageTitleTextBlock,
  'image' | 'text' | 'title'
>;

export const ImageTitleText = (props: ImageTitleTextBlockProps) => {
  const { title, text, image } = props;

  return (
    <Container
      sx={{
        mb: {
          xs: 7.5,
          md: 10,
        },
      }}
    >
      <ResponsiveTypography
        textAlign="center"
        component="h2"
        variantMap={{
          xs: 'headline5',
          md: 'headline3',
        }}
      >
        {title}
      </ResponsiveTypography>
      <Box mt={2} mb={5}>
        <ResponsiveTypography
          textAlign="center"
          color="neutral.60"
          component="h2"
          variantMap={{
            xs: 'text7',
            md: 'text5',
          }}
        >
          {text}
        </ResponsiveTypography>
      </Box>

      {image ? (
        <Box display="flex" justifyContent="center">
          <Image
            src={image?.data?.attributes.url}
            alt={image?.data?.attributes.alternativeText || ''}
            width={image?.data?.attributes.width}
            height={image?.data?.attributes.height}
            style={{
              objectFit: 'contain',
              height: 'auto',
              maxWidth: '100%',
            }}
          />
        </Box>
      ) : null}
    </Container>
  );
};
