import React from 'react';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import Image from 'next/image';

import { TBlockStepsStep } from '@/shared/api/homepage-api';
import { StepCardLeft } from './step-card-left';
import { getImageBaseProps, handleActionButtonClick } from '@/utils';

type StepCardProps = Pick<
  TBlockStepsStep,
  'title' | 'stepNumber' | 'imageDesktop' | 'imageMobile' | 'buttonText'
>;

export const StepCard = (props: StepCardProps) => {
  const { title, stepNumber, imageMobile, imageDesktop, buttonText } = props;

  return (
    <Card
      variant="animated"
      sx={{
        px: {
          xs: 2.375,
          md: 3.75,
        },
        py: 3.75,
        height: '100%',
        display: 'flex',
        width: {
          xs: 285,
          md: 'auto',
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs>
          <Stack
            mx={{
              xs: 1.375,
              md: 0,
            }}
            height="100%"
            justifyContent="space-between"
          >
            <StepCardLeft stepNumber={stepNumber} title={title} />
            {buttonText ? (
              <Button
                onClick={handleActionButtonClick}
                size="small"
                sx={(theme) => ({
                  width: 138,
                  display: { xs: 'none', md: 'block' },

                  '&:hover': {
                    background: theme.palette.secondary.dark,
                  },
                })}
              >
                {buttonText}
              </Button>
            ) : null}
          </Stack>
        </Grid>
        <Grid item xs={12} md="auto" alignSelf="flex-end">
          <Box
            mb={-3.75}
            height={190}
            display={{
              xs: 'none',
              md: 'flex',
            }}
          >
            <Image
              {...getImageBaseProps(imageDesktop)}
              style={{
                objectFit: 'contain',
                maxWidth: '100%',
                height: 'auto',
                width: 'auto',
              }}
            />
          </Box>
          <Box
            mb={-3.75}
            height={190}
            mt={0.5}
            display={{
              xs: 'flex',
              md: 'none',
            }}
            alignItems="flex-end"
          >
            <Image
              {...getImageBaseProps(imageMobile)}
              style={{
                height: 'auto',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
