import { TWhyChooseUsSection } from '@/shared/api/homepage-api';
import { Box, Grid, Typography } from '@mui/material';
import { PageContainer } from '@/shared/ui';

type WhyChooseUsSectionProps = TWhyChooseUsSection;

export const WhyChooseUsSection = (props: WhyChooseUsSectionProps) => {
  const { title, buttonText, benefits } = props;

  return (
    <PageContainer title={title} buttonText={buttonText}>
      <Grid item container xs={12} rowSpacing={1} columnSpacing={2.5}>
        {benefits.map(({ title, imageDesktop, imageMobile }, index) => (
          <Grid key={index} item xs={12} md={3}>
            <Box
              height={{ xs: 118, md: 282 }}
              borderRadius={2.5}
              overflow="hidden"
              sx={{
                background: {
                  xs: 'linear-gradient(to right top, #fe6b66, #fe6b66, #fe6b67, #fe6b67, #fe6b67, #ff7264, #ff7861, #ff7f5e, #ff8e59, #ff9d56, #ffad55, #ffbc57)',
                  md: 'radial-gradient(circle, rgba(255,188,87,1) 0%, rgba(254,107,103,1) 100%)',
                },
              }}
            >
              <Box
                position="relative"
                display="flex"
                alignItems={{ xs: 'center', md: 'end' }}
                sx={{
                  width: '100%',
                  height: '100%',
                  p: 3.75,
                  backgroundImage: {
                    xs: `url(${imageMobile?.data?.attributes?.url})`,
                    md: `url(${imageDesktop?.data?.attributes?.url})`,
                  },
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: { xs: 'contain', md: 'cover' },
                  backgroundPosition: { xs: 'right', md: 'center' },
                }}
              >
                <Typography variant="headline5" color="neutral.100" width={124}>
                  {title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
};
