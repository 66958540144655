import { useTranslations } from 'next-intl';
import { Box, Typography, useTheme, Grid, Button } from '@mui/material';
import parse, { DOMNode, domToReact, Element } from 'html-react-parser';
import React from 'react';
import { SectionOuter } from '@/shared/ui';
import {
  CardOffer,
  CardOfferContent,
  CardOfferHeader,
  OfferCardActionArea,
} from '@/shared/ui/card-offer';
import { HorizontalSlider } from '@/shared/ui/horizontal-slider';
import { IImage } from '@/shared/types';

interface ICardOffer {
  amount: string;
  term: string;
  description: string;
  id: number;
  buttonText: string;
  background: IImage;
}

interface IHomeBlockOffersProps {
  title: string;
  offers: ICardOffer[];
  onClick: () => void;
}

export const HomeBlockOffers = (props: IHomeBlockOffersProps) => {
  const { title, offers, onClick } = props;

  const t = useTranslations();

  const theme = useTheme();

  const getOfferComponent = (offerData: ICardOffer) => {
    return (
      <CardOffer
        key={offerData.id}
        sx={{
          [theme.breakpoints.down('md')]: {
            width: 316,
          },
        }}
      >
        <CardOfferHeader
          amount={{
            value: offerData.amount,
            title: t('content.cardOfferAmountTitle'),
          }}
          term={{
            value: offerData.term,
            title: t('content.cardOfferTermTitle'),
          }}
          background={offerData.background}
        />
        <CardOfferContent sx={{ flexGrow: '1' }}>
          {!!offerData.description &&
            parse(offerData.description, {
              replace: (domNode: DOMNode) => {
                if (domNode instanceof Element) {
                  switch (domNode.name) {
                    case 'p': {
                      return (
                        <Typography
                          sx={{ '& + &': { marginTop: 0.5 } }}
                          color="common.onPrimaryContainer"
                        >
                          {domToReact(domNode.children)}
                        </Typography>
                      );
                    }
                  }
                }
              },
            })}
        </CardOfferContent>
        {offerData?.buttonText && (
          <OfferCardActionArea sx={{ padding: 2 }}>
            <Button onClick={onClick} size="small" fullWidth>
              {offerData.buttonText}
            </Button>
          </OfferCardActionArea>
        )}
      </CardOffer>
    );
  };

  const offerCardsLayoutMobile = offers?.map((offerData) => {
    return (
      <React.Fragment key={offerData.id}>
        {getOfferComponent(offerData)}
      </React.Fragment>
    );
  });

  const offerCardsLayoutDesktop = offers?.map((offerData, index, array) => {
    return (
      <Grid key={offerData.id} item sm={12 / array.length}>
        {getOfferComponent(offerData)}
      </Grid>
    );
  });

  return (
    <>
      <Box display={{ xs: 'block', md: 'none' }}>
        <SectionOuter
          color="neutral.95"
          disableGutters
          sx={{
            borderRadius: 0,
          }}
        >
          <Box pb={3}>
            <Typography
              textAlign={{ md: 'center' }}
              variant="headline6"
              color="common.onSecondaryContainer"
            >
              {title}
            </Typography>
          </Box>
          <HorizontalSlider spacing={2} items={offerCardsLayoutMobile} />
        </SectionOuter>
      </Box>
      <Box display={{ xs: 'none', md: 'block' }}>
        <SectionOuter color="common.secondaryContainer">
          <Box pb={3}>
            <Typography
              textAlign={{ md: 'center' }}
              variant="headline3"
              color="common.onSecondaryContainer"
            >
              {title}
            </Typography>
          </Box>
          <Grid container spacing={2} alignItems="stretch">
            {offerCardsLayoutDesktop}
          </Grid>
        </SectionOuter>
      </Box>
    </>
  );
};
