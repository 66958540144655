import { TLinearStepsSection } from '@/shared/api/homepage-api';
import {
  IconArrow,
  IconCheck,
  PageContainer,
  ResponsiveTypography,
} from '@/shared/ui';
import { Box, Divider, Grid, List, ListItem, Typography } from '@mui/material';
import Image from 'next/image';

type LinearStepSectionProps = TLinearStepsSection;

export const LinearStepsSection = (props: LinearStepSectionProps) => {
  const { title, buttonText, steps } = props;

  return (
    <PageContainer title={title} buttonText={buttonText}>
      <Grid item container xs={12} spacing={2.5}>
        {steps.map(({ stepNumber, title, steps: nestedSteps }, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={index}
            display="flex"
            flexDirection={{ xs: 'row', md: 'column' }}
            gap={{ xs: 3, md: 4 }}
          >
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              justifyContent="space-between"
              gap={2.5}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                color="primary.100"
                width="42px"
                height="42px"
                sx={{
                  backgroundColor: 'primary.main',
                  flexShrink: 0,
                  transform: {
                    xs:
                      index !== steps.length - 1
                        ? 'rotate(90deg)'
                        : 'rotate(0)',
                    md: 'rotate(0)',
                  },
                }}
              >
                {index === steps.length - 1 ? <IconCheck /> : <IconArrow />}
              </Box>

              {index !== steps.length - 1 && (
                <>
                  <Divider
                    sx={{
                      display: { xs: 'block', md: 'none' },
                      borderRadius: '4px',
                      backgroundColor: 'primary.main',
                      width: '3px',
                      height: '100%',
                      flex: '0 auto',
                    }}
                  />

                  <Divider
                    sx={{
                      display: { xs: 'none', md: 'block' },
                      borderRadius: '4px',
                      backgroundColor: 'primary.main',
                      width: '100%',
                      height: '3px',
                      flex: 'auto 0',
                    }}
                  />
                </>
              )}
            </Box>

            <Box display="flex" flexDirection="column" gap={2.5}>
              <Box
                display="flex"
                flexDirection="column"
                gap={{ xs: 0.5, md: 1 }}
              >
                <Typography
                  variant="text7"
                  color="neutral.50"
                  textTransform="capitalize"
                >
                  {stepNumber}
                </Typography>

                <ResponsiveTypography
                  variantMap={{ xs: 'headline6', md: 'headline5' }}
                >
                  {title}
                </ResponsiveTypography>
              </Box>

              <List
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0 }}
              >
                {nestedSteps.map(({ text, icon }, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      gap: 1.5,
                      p: 0,
                    }}
                  >
                    <Image
                      src={icon.data.attributes.url}
                      alt={icon.data.attributes.alternativeText ?? 'icon'}
                      width={24}
                      height={24}
                    />

                    <ResponsiveTypography
                      color="neutral.50"
                      variantMap={{ xs: 'text6', md: 'text4' }}
                    >
                      {text}
                    </ResponsiveTypography>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
};
