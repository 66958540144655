import React from 'react';
import {
  NotStyledParser,
  ResponsiveTypography,
  SectionOuter,
} from '@/shared/ui';
import { Box, Button } from '@mui/material';
import Image from 'next/image';

import { ABTestingSimpleBanner } from '@/shared/api/homepage-api';
import { redirectToAccount } from '@/utils';
import { AB_TESTING_REDIRECT_STORAGE_KEY } from '@/shared/constants';
import { ABTestingBlockNames, setToLocalStorage } from '@/features/ab-testing';

type SimpleBannerProps = Pick<
  ABTestingSimpleBanner,
  'text' | 'buttonText' | 'title' | 'imageDesktop' | 'imageMobile'
>;

export const SimpleBanner = (props: SimpleBannerProps) => {
  const { title, imageMobile, imageDesktop, text, buttonText } = props;

  const handleClick = () => {
    setToLocalStorage(
      AB_TESTING_REDIRECT_STORAGE_KEY,
      ABTestingBlockNames.SimpleBanner,
    );
    redirectToAccount();
  };

  const titleNode = title ? (
    <ResponsiveTypography
      variantMap={{
        xs: 'headline5',
        md: 'headline1',
      }}
      sx={{
        mb: {
          xs: 1,
          md: 4,
        },
      }}
    >
      {title}
    </ResponsiveTypography>
  ) : null;

  const textNode = text ? (
    <ResponsiveTypography
      component="div"
      variantMap={{
        xs: 'text6',
        md: 'text4',
      }}
      color="neutral.50"
    >
      <NotStyledParser data={text} />
    </ResponsiveTypography>
  ) : null;

  const buttonNode = (
    <Button
      onClick={handleClick}
      sx={{
        px: 4,
        py: 2,
        width: {
          xs: '100%',
          md: 336,
        },
      }}
    >
      {buttonText}
    </Button>
  );

  const contentDesktop = (
    <SectionOuter
      color="primary.95"
      disablePaddings
      sx={{
        backgroundImage: `url(${imageDesktop?.data?.attributes?.url})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
      }}
    >
      <Box
        p={8}
        sx={{
          maxWidth: 600,
        }}
      >
        {titleNode}

        <Box mb={12.25}>{textNode}</Box>
        {buttonNode}
      </Box>
    </SectionOuter>
  );

  const contentMobile = (
    <Box>
      <Box my={2.5} mx={2} textAlign="center">
        {titleNode}
        {textNode}
      </Box>
      {imageMobile?.data ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            bgcolor: 'primary.95',
          }}
        >
          <Image
            src={imageMobile?.data?.attributes?.url}
            alt={imageMobile?.data?.attributes?.alternativeText || ''}
            width={imageMobile?.data?.attributes?.width}
            height={imageMobile?.data?.attributes?.height}
            style={{
              height: 'auto',
            }}
          />
        </Box>
      ) : null}
      <Box my={2.5} mx={2}>
        {buttonNode}
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        display={{
          xs: 'none',
          md: 'block',
        }}
      >
        {contentDesktop}
      </Box>
      <Box
        display={{
          xs: 'block',
          md: 'none',
        }}
      >
        {contentMobile}
      </Box>
    </>
  );
};
