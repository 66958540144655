import {
  THomeBlockPayingMadeEasy,
  TLinearStepsSection,
  TPageDataContent,
  TPhotoFeedbackSection,
  TWhyChooseUsSection,
} from '@/shared/api/homepage-api';
import { handleActionButtonClick } from '@/utils';
import {
  BlockNumbers,
  HomeBlockMain,
  BlockTestimonial,
  HomeBlockSteps,
  MobileAppBannerOuter,
  BlockExample,
  HomeBlockOffers,
  HomeBlockRequirements,
  BlockPayingMadeEasy,
  BlockRequirementsV2,
  DownloadAppBanner,
  BlockStepsV2,
  AmmanaTargetOffer,
  SimpleBanner,
  AmmanaStats,
  AmmanaTargetOffer_V2,
  BlockExampleWithLink,
  ImageTitleText,
  TwoColumnsBlock,
  LinearStepsSection,
  WhyChooseUsSection,
  PhotoFeedbackSection,
} from '@/components/home';
import { ComponentType } from '@/shared/types';
import dynamic from 'next/dynamic';
import { PersonQuote } from '@/shared/ui';

const BlockPartners = dynamic(() =>
  import('@/shared/ui/block-partners').then((mod) => mod.BlockPartners),
);

const PopupBanner = dynamic(() =>
  import('@/shared/ui/popup-banner').then((mod) => mod.PopupBanner),
);

const FirstLoanCalculatorOuter = dynamic(() =>
  import('src/entities/first-loan-calculator').then(
    (mod) => mod.FirstLoanCalculatorOuter,
  ),
);

const BlockVideoFeedback = dynamic(() =>
  import('@/components/home/block-video-feedback').then(
    (mod) => mod.BlockVideoFeedback,
  ),
);

const BlockFaq = dynamic(() =>
  import('@/components/home/block-faq').then((mod) => mod.BlockFaq),
);

const PresentationBannerCeyloan = dynamic(() =>
  import('@/components/common/presentation-banner-ceyloan').then(
    (mod) => mod.PresentationBannerCeyloan,
  ),
);

const PresentationBannerTambadana = dynamic(() =>
  import('@/components/common/presentation-banner-tambadana').then(
    (mod) => mod.PresentationBannerTambadana,
  ),
);

const BlockCreditLimit = dynamic(
  () => import('@/components/home/credit-limit'),
);

interface PageMapProps {
  content: TPageDataContent;
}

export const PageMap = (props: PageMapProps) => {
  const { content } = props;

  if (content?.enabled === false) {
    return null;
  }

  switch (content.__component) {
    case ComponentType.Jumbotron:
      return (
        <HomeBlockMain
          title={content.title}
          subtitle={content.subtitle}
          text={content.text}
          buttonText={content.buttonText}
          image_desktop={content.image_desktop}
          image_mobile={content.image_mobile}
          onClick={handleActionButtonClick}
        />
      );
    case ComponentType.Partners__shared:
      return (
        <BlockPartners
          title={content.title}
          partnersList={content?.partners?.data}
        />
      );
    case ComponentType.Partners:
      return (
        <BlockPartners
          title={content.title}
          partnersList={content?.partners?.data}
        />
      );
    case ComponentType.Steps:
      return (
        <HomeBlockSteps
          title={content.title}
          subtitle={content.subtitle}
          steps={content.steps}
          onClick={handleActionButtonClick}
        />
      );
    case ComponentType.Steps_V2:
      return (
        <BlockStepsV2
          title={content.title}
          step_1={content.step_1}
          step_2={content.step_2}
          step_3={content.step_3}
          step_4={content.step_4}
        />
      );
    case ComponentType.LoanExample:
      return (
        <BlockExample
          title={content.title}
          buttonText={content.buttonText}
          descriptionTitle={content.descriptionTitle}
          descriptionContent={content.descriptionContent}
        />
      );
    case ComponentType.Offers:
      return (
        <HomeBlockOffers
          title={content.title}
          offers={content.offers}
          onClick={handleActionButtonClick}
        />
      );
    case ComponentType.Requirements:
      return (
        <HomeBlockRequirements
          title={content.title}
          requirements={content.requirements}
          buttonText={content.buttonText}
          onClick={handleActionButtonClick}
        />
      );
    case ComponentType.MobileAppBanner__shared:
      return (
        <MobileAppBannerOuter
          title={content.title}
          features={content.features}
          imageAlt={content.image?.data?.attributes?.alternativeText || ''}
          imageUrl={content.image?.data?.attributes?.url}
        />
      );
    case ComponentType.MobileAppBanner:
      return (
        <MobileAppBannerOuter
          title={content.title}
          features={content.features}
          imageAlt={content.image?.data?.attributes?.alternativeText || ''}
          imageUrl={content.image?.data?.attributes?.url}
        />
      );
    case ComponentType.PresentationBanner:
      return (
        <PresentationBannerCeyloan
          buttonText={content.buttonText}
          title={content.title}
          description={content.description}
          logo={content.logo}
          imageDesktop={content.imageDesktop}
          imageMobile={content.imageMobile}
        />
      );
    case ComponentType.PresentationBannerTambadana:
      return (
        <PresentationBannerTambadana
          buttonText={content.buttonText}
          title={content.title}
          description={content.description}
          logo={content.logo}
          imageDesktop={content.imageDesktop}
          imageMobile={content.imageMobile}
        />
      );

    case ComponentType.ABCalculatorMaxSum:
    case ComponentType.FirstLoanCalculator:
      return (
        <FirstLoanCalculatorOuter
          calculator={content.calculator}
          title={content.title}
          subtitle={content.subtitle}
          title_mobile={content.title_mobile}
          subtitle_mobile={content.subtitle_mobile}
          backgroundDesktop={content.backgroundDesktop}
          imageMobile={content.imageMobile}
          imageDesktop={content.imageDesktop}
          miniBlockAbove={content.miniBlockAbove}
          backgroundColor={content.backgroundColor}
        />
      );

    case ComponentType.PayingMadeEasy:
      return (
        <BlockPayingMadeEasy
          {...(content as unknown as THomeBlockPayingMadeEasy)}
        />
      );

    case ComponentType.PopupBanner:
      return (
        <PopupBanner
          imageDesktopUrl={content?.imageDesktop?.data?.attributes?.url}
          imageUrl={content?.image?.data?.attributes?.url}
        />
      );
    case ComponentType.NumbersBlock:
      return (
        <BlockNumbers
          title={content.title}
          numbers={content.numbers}
          buttonText={content.buttonText}
        />
      );
    case ComponentType.Testimonials:
      return <BlockTestimonial title={content.title} items={content.items} />;
    case ComponentType.FAQ:
      return <BlockFaq title={content.title} items={content.items} />;
    case ComponentType.Requirements_V2:
      return (
        <BlockRequirementsV2
          title={content.title}
          cardLarge={content.cardLarge}
          cardLargeChips={content.cardLargeChips}
          cardSmallLeft={content.cardSmallLeft}
          cardSmallRight={content.cardSmallRight}
          cardLargeChipsMobile={content.cardLargeChipsMobile}
          buttonText={content.buttonText}
        />
      );
    case ComponentType.MobileAppBanner__shared_V2:
      return (
        <DownloadAppBanner
          title={content.title}
          image={content.image}
          qrCode={content.qrCode}
          googlePlayBtn={content.googlePlayBtn}
          listItems={content.listItems}
          listStyleImage={content.listStyleImage}
        />
      );
    case ComponentType.AmmanaTargetOffer:
      return (
        <AmmanaTargetOffer
          title={content.title}
          imageDesktop={content.imageDesktop}
          imageMobile={content.imageMobile}
          qrCode={content.qrCode}
          text={content.text}
        />
      );
    case ComponentType.AmmanaTargetOffer_V2:
      return (
        <AmmanaTargetOffer_V2
          titleMobile={content.titleMobile}
          titleDesktop={content.titleDesktop}
          imageDesktop={content.imageDesktop}
          imageMobile={content.imageMobile}
          imageFootnoteMobile={content.imageFootnoteMobile}
          buttonText={content.buttonText}
          text={content.text}
        />
      );
    case ComponentType.AmmanaStats:
      return <AmmanaStats title={content.title} numbers={content.rows} />;
    case ComponentType.SimpleBanner:
      return (
        <SimpleBanner
          title={content.title}
          text={content.text}
          imageMobile={content.imageMobile}
          imageDesktop={content.imageDesktop}
          buttonText={content.buttonText}
        />
      );

    case ComponentType.ExampleWithLink:
      return (
        <BlockExampleWithLink
          link={content.link}
          text={content.text}
          buttonText={content.buttonText}
        />
      );
    case ComponentType.VideoFeedback:
      return (
        <BlockVideoFeedback
          title={content.title}
          youtubeVideo={content.youtubeVideo}
        />
      );
    case ComponentType.CreditLimitSlider:
      return <BlockCreditLimit card={content.card} />;
    case ComponentType.ImageTitleText:
      return (
        <ImageTitleText
          title={content.title}
          text={content.text}
          image={content.image}
        />
      );
    case ComponentType.PersonQuote:
      return (
        <PersonQuote
          title={content.title}
          image={content.image}
          name={content.name}
          position={content.position}
        />
      );
    case ComponentType.TwoColumnsBlock:
      return (
        <TwoColumnsBlock
          leftColumn={content.leftColumn}
          rightColumn={content.rightColumn}
        />
      );
    case ComponentType.LinearStepsSection:
      return (
        <LinearStepsSection {...(content as unknown as TLinearStepsSection)} />
      );
    case ComponentType.WhyChooseUsSection:
      return (
        <WhyChooseUsSection {...(content as unknown as TWhyChooseUsSection)} />
      );
    case ComponentType.PhotoFeedbackSection:
      return (
        <PhotoFeedbackSection
          {...(content as unknown as TPhotoFeedbackSection)}
        />
      );
    default:
      return null;
  }
};
