import { Box, Card, Chip, Grid, Typography } from '@mui/material';
import { THomeBlockPayingMadeEasy } from '@/shared/api/homepage-api';
import { PageContainer, ResponsiveTypography } from '@/shared/ui';
import Image from 'next/image';

type BlockPayingMadeEasyProps = Pick<
  THomeBlockPayingMadeEasy,
  'title' | 'chips' | 'imageDesktop' | 'imageMobile' | 'buttonText' | 'partners'
>;

export const BlockPayingMadeEasy = (props: BlockPayingMadeEasyProps) => {
  const { title, chips, imageMobile, imageDesktop, partners, buttonText } =
    props;

  return (
    <PageContainer buttonText={buttonText}>
      {partners && (
        <Grid
          container
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'left' }}
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={{ xs: 1.5, md: 4 }}
          flexWrap="nowrap"
          width="100%"
          mb={{
            xs: 7.5,
            md: 10,
          }}
        >
          {partners.title ? (
            <ResponsiveTypography
              sx={{ flexShrink: 0 }}
              variantMap={{ xs: 'headline5', md: 'headline3' }}
            >
              {partners.title}
            </ResponsiveTypography>
          ) : null}

          {/** Лого партнеров десктопной версии */}
          <Box
            display={{ xs: 'none', md: 'flex' }}
            alignItems="center"
            width="100%"
          >
            <Image
              src={partners.imageDesktop?.data?.attributes?.url ?? ''}
              alt={
                partners.imageDesktop?.data?.attributes?.alternativeText ??
                'partners_logo'
              }
              width={0}
              height={0}
              sizes="100vw"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>

          {/** Лого партнеров мобильной версии */}
          <Box
            display={{ xs: 'flex', md: 'none' }}
            alignItems="center"
            width="100%"
          >
            <Image
              src={partners.imageMobile?.data?.attributes?.url ?? ''}
              alt={
                partners.imageMobile?.data?.attributes?.alternativeText ??
                'partners_logo'
              }
              width={0}
              height={0}
              sizes="100vw"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
        </Grid>
      )}

      <Card sx={{ width: '100%' }}>
        <Grid container xs={12} position="relative">
          {/** Заголовок десктопной версии */}
          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
              position: 'absolute',
              top: 30,
              left: 30,
              maxWidth: 168,
            }}
          >
            <Typography variant="headline3" component="h2" maxWidth={200}>
              {title}
            </Typography>
          </Box>

          {/** Заголовок мобильной версии */}
          <Grid
            item
            xs={12}
            md={chips?.length ? 4 : 1}
            sx={{
              display: 'flex',
              alignItems: 'end',
              pl: { xs: 0, md: 3.75 },
              py: { xs: 3, md: 3.75 },
            }}
          >
            <Box width="100%">
              <Box
                display={{
                  xs: 'block',
                  md: 'none',
                }}
                textAlign="center"
                width="100%"
              >
                <Typography variant="headline5" component="h2">
                  {title}
                </Typography>
              </Box>

              {!!chips?.length && (
                <Grid
                  container
                  spacing={0.75}
                  display={{
                    xs: 'none',
                    md: 'flex',
                  }}
                >
                  {chips?.map((item) => (
                    <Grid item xs="auto" key={item.id}>
                      <Chip label={item.label} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={chips?.length ? 8 : 11}>
            <Box
              sx={{
                height: {
                  xs: 320,
                  sm: 180,
                  md: 290,
                },
                backgroundImage: {
                  xs: `url(${imageMobile?.data?.attributes?.url})`,
                  sm: `url(${imageDesktop?.data?.attributes?.url})`,
                },
                backgroundRepeat: 'no-repeat',
                backgroundPosition: {
                  xs: 'top center',
                  sm: 'top right',
                  md: 'bottom left',
                },
                backgroundSize: 'cover',
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </PageContainer>
  );
};
