import React from 'react';
import { Box, Chip } from '@mui/material';
import { ResponsiveTypography } from '@/shared/ui';

interface StepCardLeftProps {
  stepNumber: string;
  title: string;
}

export const StepCardLeft = (props: StepCardLeftProps) => {
  const { stepNumber, title } = props;

  return (
    <Box>
      <Chip label={stepNumber} size="medium" variant="outlined" />

      <ResponsiveTypography
        sx={{
          mt: 1.25,
          display: 'block',
          width: {
            md: 170,
          },
        }}
        variantMap={{
          xs: 'headline7',
          md: 'headline6',
        }}
      >
        {title}
      </ResponsiveTypography>
    </Box>
  );
};
