import { PageContainer } from '@/shared/ui';
import { TPhotoFeedbackSection } from '@/shared/api/homepage-api';
import Image from 'next/image';
import { Grid } from '@mui/material';
import Carousel from 'react-multi-carousel';

type PhotoFeedbackProps = TPhotoFeedbackSection;

const responsive = {
  mobile: {
    items: 0.5,
    breakpoint: {
      min: 0,
      max: 600,
    },
  },
  tablet: {
    items: 2,
    breakpoint: {
      min: 600,
      max: 1244,
    },
  },
  desktop: {
    items: 2.5,
    breakpoint: {
      min: 1244,
      max: 1600,
    },
  },
  largeDesktop: {
    items: 3.5,
    breakpoint: {
      min: 1244,
      max: 1920,
    },
  },
  superLargeDesktop: {
    items: 5,
    breakpoint: {
      min: 1920,
      max: 3000,
    },
  },
  extraLargeDesktop: {
    items: 8,
    breakpoint: {
      min: 3000,
      max: 6000,
    },
  },
};

export const PhotoFeedbackSection = (props: PhotoFeedbackProps) => {
  const { title, buttonText, list } = props;

  return (
    <PageContainer
      title={title}
      buttonText={buttonText}
      sx={{ maxWidth: '100% !important', px: '0 !important' }}
      buttonSx={{ px: { xs: 2, sm: 3, md: 0 } }}
    >
      <Grid
        item
        container
        xs={12}
        display="block"
        sx={{
          '& .carouselItem': {
            paddingRight: '16px',
          },
        }}
      >
        <Carousel
          ssr
          autoPlay
          swipeable
          draggable
          infinite
          focusOnSelect={false}
          pauseOnHover
          centerMode
          rewind={false}
          partialVisible={false}
          itemClass="carouselItem"
          rewindWithAnimation={false}
          shouldResetAutoplay={false}
          showDots={false}
          slidesToSlide={1}
          arrows={false}
          responsive={responsive}
        >
          {list?.data?.map((image, index) => (
            <Grid item key={index} sx={{ cursor: 'pointer' }}>
              <Image
                src={image.attributes.url}
                alt={image.attributes.alternativeText || ''}
                width={0}
                height={0}
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: 360,
                  maxHeight: 508,
                  pointerEvents: 'none',
                }}
              />
            </Grid>
          ))}
        </Carousel>
      </Grid>
    </PageContainer>
  );
};
