import { Box, Grid } from '@mui/material';
import React from 'react';
import {
  HorizontalSlider,
  ResponsiveTypography,
  SectionContainer,
} from '@/shared/ui';
import { TBlockStepsV2 } from '@/shared/api/homepage-api';
import {
  StepCard,
  StepCardOutlineBg,
} from '@/components/home/block-steps-v2/cards';

type BlockStepsV2Props = Pick<
  TBlockStepsV2,
  'title' | 'step_1' | 'step_2' | 'step_3' | 'step_4'
>;

export const BlockStepsV2 = (props: BlockStepsV2Props) => {
  const { title, step_1, step_2, step_3, step_4 } = props;

  const step1 = step_1 ? (
    <StepCard
      title={step_1.title}
      stepNumber={step_1.stepNumber}
      imageMobile={step_1.imageMobile}
      imageDesktop={step_1.imageDesktop}
      buttonText={step_1.buttonText}
    />
  ) : null;

  const step2 = step_2 ? (
    <StepCard
      title={step_2.title}
      stepNumber={step_2.stepNumber}
      imageMobile={step_2.imageMobile}
      imageDesktop={step_2.imageDesktop}
      buttonText={step_2.buttonText}
    />
  ) : null;

  const step3 = step_3 ? (
    <StepCard
      title={step_3.title}
      stepNumber={step_3.stepNumber}
      imageMobile={step_3.imageMobile}
      imageDesktop={step_3.imageDesktop}
      buttonText={step_3.buttonText}
    />
  ) : null;

  const step4 = step_4 ? (
    <StepCardOutlineBg
      title={step_4.title}
      stepNumber={step_4.stepNumber}
      imageMobile={step_4.imageMobile}
      imageDesktop={step_4.imageDesktop}
    />
  ) : null;

  const desktopStepsContent = (
    <Box
      display={{
        xs: 'none',
        md: 'block',
      }}
    >
      <Grid container spacing={2.5}>
        {step_1 ? (
          <Grid item xs={6}>
            {step1}
          </Grid>
        ) : null}
        {step_2 ? (
          <Grid item xs={6}>
            {step2}
          </Grid>
        ) : null}
        {step_3 ? (
          <Grid item xs={6}>
            {step3}
          </Grid>
        ) : null}
        {step_4 ? (
          <Grid item xs={6}>
            {step4}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );

  const mobileStepsContent = (
    <Box
      display={{
        xs: 'block',
        md: 'none',
      }}
    >
      <HorizontalSlider
        items={[
          <React.Fragment key={1}>{step1}</React.Fragment>,
          <React.Fragment key={2}>{step2}</React.Fragment>,
          <React.Fragment key={3}>{step3}</React.Fragment>,
          <React.Fragment key={4}>{step4}</React.Fragment>,
        ]}
        spacing={1.25}
      />
    </Box>
  );

  return (
    <SectionContainer>
      <Box
        mb={{
          xs: 2.5,
          md: 5,
        }}
      >
        <ResponsiveTypography
          textAlign={{
            md: "center"
          }}
          component="h2"
          variantMap={{
            xs: 'headline5',
            md: 'headline3',
          }}
        >
          {title}
        </ResponsiveTypography>
      </Box>
      {desktopStepsContent}
      {mobileStepsContent}
    </SectionContainer>
  );
};
