import { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import Image from 'next/image';
import { NotStyledParser, SectionOuter } from '@/shared/ui';
import { TAmmanaTargetOfferV2 } from '@/shared/api/homepage-api';
import { getGeneratedOneLinkUrl, getImageBaseProps } from '@/utils';
import { useCommonApi } from '@/shared/contexts';

type AmmanaTargetOfferV2Props = Pick<
  TAmmanaTargetOfferV2,
  | 'titleMobile'
  | 'titleDesktop'
  | 'text'
  | 'imageDesktop'
  | 'imageMobile'
  | 'imageFootnoteMobile'
  | 'buttonText'
>;

export const AmmanaTargetOffer_V2 = (props: AmmanaTargetOfferV2Props) => {
  const {
    titleMobile,
    titleDesktop,
    imageMobile,
    imageDesktop,
    imageFootnoteMobile,
    text,
    buttonText,
  } = props;

  const commonApiContext = useCommonApi();
  const googlePlayLink =
    commonApiContext?.layoutSettings?.attributes.globalLinks?.googlePlayLink;

  const [googleAppLink, setGoogleAppLink] = useState(googlePlayLink);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setGoogleAppLink(getGeneratedOneLinkUrl(googlePlayLink));
    }
  }, [googlePlayLink]);

  return (
    <>
      <Box display={{ md: 'none' }} mt={2.5}>
        <Box px={2.5} mb={2.5}>
          {titleMobile ? (
            <Typography
              component="div"
              variant="headline5"
              mb={1}
              color="neutral.20"
              textAlign="center"
            >
              <NotStyledParser data={titleMobile} />
            </Typography>
          ) : null}

          {text ? (
            <Typography
              component="div"
              variant="text6"
              color="neutral.50"
              textAlign="center"
            >
              <NotStyledParser data={text} />
            </Typography>
          ) : null}
        </Box>

        <Image
          {...getImageBaseProps(imageMobile)}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
        <Box px={2.5} mt={2.5}>
          <Box px={2} mb={2.5}>
            <Image
              {...getImageBaseProps(imageFootnoteMobile)}
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </Box>
          {buttonText && googleAppLink ? (
            <Button href={googleAppLink} target="_blank" fullWidth>
              {buttonText}
            </Button>
          ) : null}
        </Box>
      </Box>
      <SectionOuter
        color="secondary.95"
        disablePaddings
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
          backgroundImage: `url(${imageDesktop?.data?.attributes.url})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          p: 7,
          mb: 7.25,
        }}
      >
        <Box
          sx={{
            maxWidth: 500,
            flexDirection: 'column',
            alignItems: 'center',
          }}
          display={{
            xs: 'flex',
            md: 'block',
          }}
        >
          {titleDesktop ? (
            <Typography
              component="div"
              variant="headline1"
              color="neutral.20"
              mb={4}
            >
              <NotStyledParser data={titleDesktop} />
            </Typography>
          ) : null}
          {text ? (
            <Typography
              component="div"
              variant="text4"
              color="neutral.50"
              mb={12.25}
            >
              <NotStyledParser data={text} />
            </Typography>
          ) : null}
          {buttonText && googleAppLink ? (
            <Button
              href={googleAppLink}
              target="_blank"
              sx={{
                width: 336,
              }}
            >
              {buttonText}
            </Button>
          ) : null}
        </Box>
      </SectionOuter>
    </>
  );
};
